/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */


// .pagination .page-item {
// 	width: 32px !important;
// 	height: 32px !important;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	border-radius: 8px !important;
// }

// .page-link:focus {
// 	box-shadow: none !important;
// }

// .pagination .page-item a {
// 	font-family: "plusJakara_semibold";
// 	border: transparent;
// 	background-color: transparent;
// 	color: #111827;
// }

// .pagination li.page-item.active {
// 	border: transparent;
// 	background: #f8f8f8 !important;
// 	color: #111827 !important;
// }

// .pagination .prev-item {
// 	margin-right: 20px;
// 	border: 1px solid gainsboro;
// }

// .page-item.prev-item.disabled {
// 	border: 1px solid gainsboro;
// 	background-color: gainsboro !important;
// }

// .page-item.next-item.disabled {
// 	border: 1px solid gainsboro;
// 	background-color: gainsboro !important;
// }

// .page-item.disabled .page-link {
// 	background-color: transparent !important;
// }

// .pagination .prev-item a {
// 	border: transparent;
// 	background-color: transparent;
// }

// .pagination .next-item {
// 	margin-left: 20px;
// 	border: 1px solid gainsboro;
// }

// .page-item .next-item .disabled {
// 	border: 1px solid gainsboro;
// 	background-color: gainsboro !important;
// }

// .pagination .next-item a {
// 	border: transparent;
// 	background-color: transparent;
// }

// @media (max-width: 768px) {
// 	.pagination .page-item {
// 		width: 26px !important;
// 		height: 26px !important;
// 		font-size: 14px;
// 	}

// 	.pagination .prev-item {
// 		margin-right: 5px;
// 	}

// 	.pagination .next-item {
// 		margin-left: 5px;
// 	}
// }

// [dir=ltr] .spinner-border {
// 	border-right-color: transparent !important;
// 	animation: 0.75s linear infinite spinner-border-ltr !important;
// }

// @keyframes spinner-border-ltr {
// 	to {
// 		transform: rotate(360deg);
// 	}
// }
// .img-overlay {
// 	background-color: rgba(0, 0, 0, 0.4);
// 	position: absolute;
// 	inset: 0;
// }
// .sc-fUnmch.iQCFDAI,
// .eDckxA{
// 	background-color: transparent !important;
// }

/* Hover animation styles */
// .card-hover {
// 	transition: transform 0.3s ease, box-shadow 0.3s ease;
// 	z-index: 0;
// 	position: relative;
// }

// .card-hover:hover {
// 	transform: scale(1.02);
// 	z-index: 50;
// 	box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
// }

.cu-border {
	border-left: 1px solid #ebe9f1 !important;

	@media (max-width:992px) {

		border-left: none !important;
		border-top: 1px solid #ebe9f1 !important;
		padding-top: 0.5rem !important;
	}
}

.btn1:hover {
	opacity: 0.9;
	text-decoration: underline !important;
}